/* You can add global styles to this file, and also import other style files */
/* @import "~font-awesome/css/font-awesome.css"; */
/* @import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,500,500i,600,600i,700,700i,800|Open+Sans:400,400i,600,600i,700,700i,800&display=swap"); */

/* ==========
   1.BASIC
   ========== */

body {
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden !important;

  position: relative;
  background-color: rgb(248, 249, 250);
}

body::-webkit-scrollbar {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.spacer-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.spacer-y-1 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.spacer-y-2 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.spacer-y-3 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.spacer-y-4 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.spacer-y-5 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

.spacer-y-6 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}